.carousel-indicators {
  margin-right: 5%;
  margin-left: 5%;

  li {
    height: 10px;
    background-color: #fff;
    transition: transform 0.2s ease, opacity 0.6s ease !important;

    &:hover {
      transform: scale(1.3);
    }
  }
}

.carousel-caption {
  border-radius: 4px;
  padding: 8px;
  left: 3%;
  right: 3%;
  margin-bottom: 10px;

  & > h3 {
    margin: 0;
  }

  p {
    font-size: 75%;
  }
}

.show-on-hover {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.show-on-hover:hover {
  opacity: 1;
}

.full-screen-image-button {
  position: absolute;
  top: 25px !important;
  right: 25px !important;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  opacity: 0.5;
  transition: opacity 0.15s ease !important;

  .fa {
    font-size: 20px;
  }
}

.full-screen-carousel {
  overflow: auto;
  display: flex;
  justify-content: center;
  border-radius: 0 !important;

  .carousel,
  .carousel-inner,
  .carousel-item,
  .carousel-image-placeholder {
    height: 100%;
  }
  img {
    border-radius: 0 !important;
  }
}
.carousel-image-placeholder {
  width: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;

  height: 300px;
  @media only screen and (min-width: 768px) {
    height: 500px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
