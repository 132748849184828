.footer-heart {
  fill: rgb(251, 74, 89);
  margin: 0px 5px;
  width: 16px;
  animation: 0.8s ease 0s infinite normal none running heartBeat;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  32% {
    transform: scale(1);
  }
  48% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
