nav {
  a.nav-link.active {
    color: orange !important;
  }
  a.nav-link:hover {
    color: lighten(orange, 25%) !important;
  }
}
.nav-tabs-navigation {
  a.nav-link.active {
    font-weight: bold;
  }
  a.nav-link:hover {
    cursor: pointer;
  }
}
